import { Navigation } from "./components/navigation";
import { Contact } from "./components/contact";

const Privacy = () => {
    const containerStyle = {
        all: 'initial',
        display: 'block',
        boxSizing: 'border-box',
        color: 'black',
        fontSize: '16px',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        backgroundColor: 'white',
    };

    const headingStyle = {
        color: '#333',
        marginBottom: '15px',
    };

    const h1Style = { ...headingStyle, fontSize: '28px' };
    const h2Style = { ...headingStyle, fontSize: '22px', marginTop: '25px' };
    const h3Style = { ...headingStyle, fontSize: '18px', marginTop: '20px' };

    const paragraphStyle = {
        marginBottom: '15px',
    };

    const listStyle = {
        marginBottom: '15px',
        paddingLeft: '20px',
    };

    const listItemStyle = {
        marginBottom: '5px',
    };

    return (
        <div style={containerStyle}>
            <Navigation />
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h1 style={h1Style}>Privacy Policy for Recipe Remix</h1>

            <p style={paragraphStyle}>Last updated: September 25, 2024</p>

            <h2 style={h2Style}>1. Introduction</h2>
            <p style={paragraphStyle}>Welcome to Recipe Remix ("we," "our," or "us"). We are committed to protecting your personal information and your right to privacy. This privacy policy describes how we collect, use, and share information when you use our mobile application (the "App").</p>

            <h2 style={h2Style}>2. Information We Collect</h2>
            <h3 style={h3Style}>2.1 Information you provide to us:</h3>
            <ul style={listStyle}>
                <li style={listItemStyle}>Account information (e.g., name, email address)</li>
                <li style={listItemStyle}>User preferences (e.g., dietary restrictions, favorite cuisines)</li>
                <li style={listItemStyle}>User-generated content (e.g., custom recipes, reviews)</li>
            </ul>

            <h3 style={h3Style}>2.2 Information automatically collected:</h3>
            <ul style={listStyle}>
                <li style={listItemStyle}>Usage data (e.g., features used, time spent in the app)</li>
                <li style={listItemStyle}>Device information (e.g., device type, operating system)</li>
                <li style={listItemStyle}>Log data (e.g., IP address, access times)</li>
            </ul>

            <h2 style={h2Style}>3. How We Use Your Information</h2>
            <p style={paragraphStyle}>We use the collected information to:</p>
            <ul style={listStyle}>
                <li style={listItemStyle}>Provide and improve our AI-powered recipe creation service</li>
                <li style={listItemStyle}>Personalize your experience and recipe recommendations</li>
                <li style={listItemStyle}>Communicate with you about our service</li>
                <li style={listItemStyle}>Ensure the security and proper functioning of our App</li>
            </ul>

            <h2 style={h2Style}>4. AI and Recipe Creation</h2>
            <p style={paragraphStyle}>Our App uses artificial intelligence to create and recommend recipes. This process involves:</p>
            <ul style={listStyle}>
                <li style={listItemStyle}>Analyzing your preferences and dietary information</li>
                <li style={listItemStyle}>Processing aggregated, anonymized user data to improve recipe generation</li>
                <li style={listItemStyle}>We do not use personal information in the AI training process beyond your explicitly provided preferences</li>
            </ul>

            <h2 style={h2Style}>5. Sharing of Your Information</h2>
            <p style={paragraphStyle}>We do not sell your personal information. We may share your information in the following situations:</p>
            <ul style={listStyle}>
                <li style={listItemStyle}>With your consent</li>
                <li style={listItemStyle}>To comply with legal obligations</li>
                <li style={listItemStyle}>With service providers who assist in our operations</li>
                <li style={listItemStyle}>In the event of a business transfer or merger</li>
            </ul>

            <h2 style={h2Style}>6. Data Retention and Security</h2>
            <p style={paragraphStyle}>We retain your information for as long as necessary to provide our services and comply with legal obligations. We implement appropriate technical and organizational measures to protect your information.</p>

            <h2 style={h2Style}>7. Your Rights and Choices</h2>
            <p style={paragraphStyle}>Depending on your location, you may have rights regarding your personal information, including:</p>
            <ul style={listStyle}>
                <li style={listItemStyle}>Access to your data</li>
                <li style={listItemStyle}>Correction of inaccurate data</li>
                <li style={listItemStyle}>Deletion of your data</li>
                <li style={listItemStyle}>Opting out of certain data uses</li>
            </ul>

            <h2 style={h2Style}>8. Children's Privacy</h2>
            <p style={paragraphStyle}>Our App is not intended for children under 13. We do not knowingly collect information from children under 13.</p>

            <h2 style={h2Style}>9. Changes to This Policy</h2>
            <p style={paragraphStyle}>We may update this policy from time to time. We will notify you of any significant changes by posting the new policy on this page.</p>

            <h2 style={h2Style}>10. Contact Us</h2>
            <p style={paragraphStyle}>If you have any questions about this privacy policy, please contact us at:</p>
            <p style={paragraphStyle}>support@reciperemixapp.com</p>
        </div>
    );
};


export default Privacy;